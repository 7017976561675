.bg-blue {
  background: url(../images/charte/bg-blue.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}



////////////// boutons

.btn-fleche {
  @extend .btn-accueil;
  background: $brand-second;
  white-space: normal;
  position: relative;
  padding: 10px 50px;
  border: 2px solid;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  border: solid 1px $brand-second;
  //margin-bottom: 50px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;


  display: inline-block;

  &:hover {
    background: white;
    color: $brand-second;
    border-radius: 50px;
    border: solid 1px $brand-second;
    padding-right: 70px;
  }

  &::after {
    position: absolute;
    margin-left: 20px;
    content: "\f061";
    font-family: fontAwesome;
    color: $brand-second;
    -webkit-transition: all .8s ease-out .0s;
    transition: all .8s ease-out .0s;
  }
}

.btn-fleche-2 {
  @extend .btn-accueil;
  background: white;
  white-space: normal;
  position: relative;
  padding: 10px 50px;
  border: 2px solid;
  color: $brand-second;
  font-weight: bold;
  border-radius: 50px;
  border: solid 1px $brand-second;
  //margin-bottom: 50px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;

  display: inline-block;

  &:hover {
    background: $brand-second;
    color: white;
    border-radius: 50px;
    border: solid 1px $brand-second;
    padding-right: 70px;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }

  &::after {
    position: absolute;
    margin-left: 20px;
    content: "\f061";
    font-family: fontAwesome;
    color: white;
    -webkit-transition: all .8s ease-out .0s;
    transition: all .8s ease-out .0s;
  }
}


.crossfit1 {

  .block2 {
    background: $brand-primary;
    padding: 5%;
    padding-bottom: 3%;
    border-radius: 0px;
    color: white;
    position: relative;
    border: solid 5px $brand-second;
  }

  @media(min-width: $screen-md-min) {
    .divider {
      display: block;
      position: absolute;
      left: calc(50%);
      top: 0vh;
      transform: translate3d(-50%, -50%, 0);
      border-top:none;
    } 
  
    .divider img{
      max-height:150px;
    }

  }


}

.ta {
	padding: 0 0 25px 0;
	position: relative;


	&::after {
		content: '';
	  position: absolute;
		bottom: 0;
		left: 0;
    height: 5px;
    background-color: $brand-second;	
    width: 21rem;

    @media(min-width: $screen-md-min) {
      width: 5rem;
    }
	}
}


.s1 {

  .block1{
    height: 70vh;
  }

  .block2 {
    background: rgba($brand-primary, 0.98);
    padding: 5%;
    padding-bottom: 3%;
    border-radius: 0px;
    color: white;
    border: solid 5px $brand-second;
    margin-left:-360px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);

    @media (max-width:$screen-sm-min) {
          margin-left:0px;
          width:90%;
          margin:auto;
          margin-top: 10px;
       }

  }

  .divider {
    display: block;
    position: absolute;
    left: calc(50%);
    top: 0vh;
    transform: translate3d(-50%, -50%, 0);
    border-top:none;
  } 

  .divider img{
    max-height:150px;
  }


}


.bg-move {
  background-attachment: fixed;
}


.border-second  {
  border: 2px $brand-second solid;
}

.tarif {
  
  .block2 {
    background: $brand-second;
    padding: 5%;
    padding-bottom: 3%;

    border-radius: 0px;
    color: white;
    position: relative;

    border: solid 5px $brand-primary;
  }

  .divider {
    display: block;
    position: absolute;
    left: calc(50%);
    top: 0vh;
    transform: translate3d(-50%, -50%, 0);
    border-top:none;
  } 

  .divider img{
    max-height:150px;
  }


}